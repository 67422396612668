import React from 'react';
import cn from 'classnames';
import { Icon, IconType, Button, ButtonVariantType } from '@dealroadshow/uikit';
import styles from './contextMenuItem.scss';

interface IProps {
  iconType: IconType,
  title: string,
  isWarning?: boolean,
  isDisabled?: boolean,
  dataTest?: string,
}

const ContextMenuItem = ({ iconType, title, isWarning, isDisabled, dataTest }: IProps) => (
  <Button
    variant={ ButtonVariantType.link }
    className={ cn(styles.item, {
      [styles.isWarning]: isWarning,
      [styles.isDisabled]: isDisabled,
    }) }
    disabled={ isDisabled }
    dataTest={ dataTest }
  >
    <Icon
      type={ iconType }
      className={ styles.icon }
    />
    <span>{ title }</span>
  </Button>
);

export default ContextMenuItem;
