import { groupValidators } from '@/ui/shared/components/Form/validators/helpers';
import { validateMinLength } from '@/ui/shared/components/Form/validators/validateMinLength';
import { validateMaxLength } from '@/ui/shared/components/Form/validators/validateMaxLength';

const questionField = { fieldName: 'questionText', fieldCaption: 'Question' };

const validateQuestions = (questions) => questions.map((question) => (!question[questionField.fieldName] ? undefined : {
    categoryId: !question.categoryId ? 'Category is required' : undefined,
    questionText: groupValidators(
      validateMinLength({
        ...questionField,
        length: 10,
        value: question[questionField.fieldName],
      }),
      validateMaxLength({
        ...questionField,
        length: 2500,
        value: question[questionField.fieldName],
      }),
    )?.[questionField.fieldName],
  }),
);

export default (values) => {
  const validatedQuestions = validateQuestions(values.questions);

  const isEmptyQuestions = !validatedQuestions.filter(Boolean).length;

  if (isEmptyQuestions) {
    return {
      questions: [{
        questionText: 'Question is required',
        categoryId: !values.questions[0]?.categoryId ? 'Category is required' : undefined,
      }],
    };
  }

  return { questions: validatedQuestions };
};
