import React, { createContext, useContext } from 'react';
import { IVirtualDataTableProps } from './interfaces';
import VirtualListContextProvider, {
  useVirtualListContext,
} from '@/ui/shared/components/Tables/VirtualDataTable/VirtualListContext';

type VirtualDataTableContextType = ReturnType<typeof useVirtualListContext>;

export const VirtualDataTableContext = createContext<VirtualDataTableContextType & {
  dataTableProps: IVirtualDataTableProps,
}>(undefined);

export const useVirtualDataTableContext = () => {
  const context = useContext(VirtualDataTableContext);
  if (!context) {
    throw new Error('useVirtualDataTableContext must be used within a VirtualDataTableContextProvider');
  }
  return context;
};

interface IProps extends IVirtualDataTableProps {
  children: React.ReactNode,
}

const VirtualDataTableContextProvider = ({ children, ...props }: IProps) => (
  <VirtualDataTableContext.Provider
    value={ {
      ...useVirtualListContext(),
      dataTableProps: props,
    } }
  >
    { children }
  </VirtualDataTableContext.Provider>
);

export default ({ children, ...props }: IProps) => (
  <VirtualListContextProvider { ...props }>
    <VirtualDataTableContextProvider { ...props }>
      { children }
    </VirtualDataTableContextProvider>
  </VirtualListContextProvider>
);
