import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import { dataTableStyles } from '@dealroadshow/uikit';
import { useFileUploaderContext } from '@/dataroom/application/FileUploader/FileUploaderContext';
import VirtualDataTable, { TVirtualBody, IColumn } from '@/ui/shared/components/Tables/VirtualDataTable';
import FileWithUploadAndPath from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/models/FileWithUploadAndPath';
import screenVariables from '@dealroadshow/uikit/dist/lib/styles/screen/screen.scss';
import stylesUploaderActions
  from '@/dataroom/ui/common/DataroomExplorer/Modals/UploaderModal/FileUploaderItemActions/fileUploaderItemActions.scss';
import styles from '../../uploaderModal.scss';

const TIME_INACTIVE_AUTO_SCROLL = 30000; // 30 sec

const DEFAULT_LIST_CONTAINER_HEIGHT = 274;
const PERMISSIONS_LIST_CONTAINER_HEIGHT = 96;
const ERRORS_LIST_CONTAINER_HEIGHT = 303;
const LOADABLE_LIST_CONTAINER_HEIGHT = 360;
const TABLE_ROW_HEIGHT = 30;

interface IProps {
  getColumns: (isUploading: boolean, isUploadComplete: boolean) => object[],
  files: FileWithUploadAndPath[],
  uploadingFileIndex: number,
  isPermissionChangeVisible: boolean,
  isUploadComplete: boolean,
}

const rowClassNameCallback = (data) => {
  const { upload } = data;
  if (upload.isSuccess()) {
    return stylesUploaderActions.success;
  }
  if (upload.isFailure()) {
    return stylesUploaderActions.failure;
  }
  return null;
};

const FilesTable = ({
  getColumns,
  files,
  uploadingFileIndex,
  isPermissionChangeVisible,
  isUploadComplete,
}: IProps) => {
  const {
    sortFiles,
    sortParams,
    isUploading,
    isRetryUploading,
    isUploadErrors,
    isAutoScrollEnabled,
    enableAutoScroll,
    disableAutoScroll,
    isAddingChildren,
    isUploadCompleteWithNoErrors,
  } = useFileUploaderContext();
  const bodyRef = useRef<TVirtualBody>(null);
  const isScrollAutoChanged = useRef(false);

  const {
    sortBy,
    sortOrder,
  } = sortParams.current;

  const isUploadedWithNoErrors = isUploadCompleteWithNoErrors();

  const isRetryUploadingFiles = isRetryUploading();

  useEffect(() => (() => {
    enableAutoScroll();
  }), [enableAutoScroll]);

  useEffect(() => {
    if (
      !isUploading ||
      !isAutoScrollEnabled.current ||
      !files.length ||
      !~uploadingFileIndex
    ) {
      return;
    }

    setIsScrollAutoChanged(true);

    if (bodyRef.current) {
      bodyRef.current.scrollToItem(uploadingFileIndex, 'center');
    }
  }, [uploadingFileIndex]);

  const setIsScrollAutoChanged = (value: boolean) => {
    isScrollAutoChanged.current = value;
  };

  const debouncedEnableAutoScroll = useCallback(debounce(enableAutoScroll, TIME_INACTIVE_AUTO_SCROLL), [
    enableAutoScroll,
    TIME_INACTIVE_AUTO_SCROLL,
  ]);

  const handleScroll = useCallback(() => {
    if (!isScrollAutoChanged.current && isAutoScrollEnabled) {
      disableAutoScroll();
      debouncedEnableAutoScroll();
    }
    setIsScrollAutoChanged(false);
  }, [disableAutoScroll, enableAutoScroll, isAutoScrollEnabled]);

  const columns = useMemo(
    () => getColumns(isUploading, isUploadComplete), [getColumns, isUploadComplete, isUploading],
  );

  const getBodyHeight = () => {
    let bodyHeight = DEFAULT_LIST_CONTAINER_HEIGHT;

    if (isPermissionChangeVisible && !isUploading) {
      bodyHeight = PERMISSIONS_LIST_CONTAINER_HEIGHT;
    }

    if (isUploadErrors()) {
      bodyHeight = window.innerWidth <= parseInt(screenVariables.screenS)
        ? Math.min(files.length * TABLE_ROW_HEIGHT, ERRORS_LIST_CONTAINER_HEIGHT)
        : ERRORS_LIST_CONTAINER_HEIGHT;
    }

    if (
      (isUploading && !isUploadComplete && !isRetryUploadingFiles) ||
      (isAddingChildren && isUploadedWithNoErrors)
    ) {
      bodyHeight = LOADABLE_LIST_CONTAINER_HEIGHT;
    }

    return bodyHeight;
  };

  if (files.length === 0) {
    return null;
  }

  return (
    <VirtualDataTable
      columns={ columns as IColumn[] }
      bodyHeight={ getBodyHeight() }
      containerClassName={ cn(styles.dataTableContainer, stylesUploaderActions.dataTableContainer) }
      className={ cn(styles.dataTable, dataTableStyles.isHoverable) }
      rowClassNameCallback={ rowClassNameCallback }
      data={ files }
      onSortChange={ sortFiles }
      sortBy={ sortBy }
      sortOrder={ sortOrder }
      bodyRef={ bodyRef }
      onScroll={ handleScroll }
      dataTest="uploadTable"
    />
  );
};

export default React.memo(FilesTable);
