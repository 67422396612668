import { groupValidators } from '@/ui/shared/components/Form/validators/helpers';
import { validateRequiredFields } from '@/ui/shared/components/Form/validators/validateRequiredFields';
import { validateMaxLength } from '@/ui/shared/components/Form/validators/validateMaxLength';
import { validateFilesystemItemName } from '@/ui/shared/components/Form/validators/validateFilesystemItemName';

const fieldsList = [
  { fieldName: 'folderName', fieldCaption: 'Folder name' },
];

export default (values) => groupValidators(
  validateRequiredFields(
    fieldsList,
    {
      folderName: values.folderName?.trim(),
    },
  ),
  validateMaxLength({
    value: values.folderName,
    length: 235,
    fieldName: 'folderName',
    fieldCaption: 'Folder name',
  }),
  validateFilesystemItemName({
    value: values.folderName,
    fieldName: 'folderName',
    fieldCaption: 'Folder name',
  }),
);
