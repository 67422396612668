import React, { createContext, useContext, useState } from 'react';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useDIContext } from '@/Framework/DI/DIContext';
import LinkRepository from '@/dataroom/infrastructure/repository/LinkRepository';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import FileRepository from '@/dataroom/infrastructure/repository/FileRepository';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import ErrorCodeHelper from '@finsight/error-codes';
import useIsMobile from '@/Framework/hooks/useIsMobile';
import { isScreenS } from '@dealroadshow/uikit/dist/lib/styles/screen/screen';
import { NotificationManager } from '@/Framework/Notification';
import styles from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/documentViewer.scss';

const useDocumentPreview = () => {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();
  const isMobile = useIsMobile(isScreenS);
  const { watermarking, stagingWatermarking } = dataroom || {};
  const [previewItem, setPreviewItem] = useState(null);
  const [defaultSearch, setDefaultSearch] = useState('');
  const [defaultMode, setDefaultMode] = useState('');
  const [fileWatermark, setFileWatermark] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [isLinkAnalyticsTracking, setIsLinkAnalyticsTracking] = useState(false);
  const [isError, setIsError] = useState(false);

  const previewFile = async (file: IFilesystemListItem, search: string = '', mode: string = '') => {
    if (!file.previewable) {
      setPreviewItem(file);
      return;
    }

    if (isMobile && dataroom.blurEnabled) {
      NotificationManager.info(
        <div className={ styles.securityMobileNotification }>
          Mobile viewing is disabled due to enhanced document security.
          Please view this document from your desktop computer.
        </div>,
      );
      return;
    }

    try {
      setIsFetching(true);
      setPreviewItem(file);
      setDefaultSearch(search);
      setDefaultMode(mode);

      const fileRepository = container.get<FileRepository>(FileRepository);

      await fileRepository.checkPreviewAvailability({
        fileId: file.id,
        dataroomId: dataroom.id,
      });

      if (file.isStaging ? stagingWatermarking : watermarking) {
        const response = await fileRepository.getPreviewWatermark({
          fileId: file.id,
          dataroomId: dataroom.id,
        });

        setFileWatermark(response.text);
      }
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
      if (error?.getCode() === ErrorCodeHelper.getCodeByName('DATAROOM_FILESYSTEM_ELEMENT_ACCESS_RESTRICTED')) {
        resetPreview();
      } else {
        setIsError(true);
      }
    } finally {
      setIsFetching(false);
    }
  };

  const trackLinkPreviewAnalytics = async (link) => {
    setIsLinkAnalyticsTracking(true);

    try {
      const linkRepository = container.get<LinkRepository>(LinkRepository);
      await linkRepository.preview({
        linkId: link.id,
        dataroomId: dataroom.id,
      });
      resetPreview();
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsLinkAnalyticsTracking(false);
    }
  };

  const resetPreview = () => {
    setIsFetching(false);
    setIsError(false);
    setPreviewItem(null);
    setIsLinkAnalyticsTracking(false);
    setFileWatermark('');
  };

  return {
    previewItem,
    defaultSearch,
    defaultMode,
    isLinkAnalyticsTracking,
    trackLinkPreviewAnalytics,
    fileWatermark,
    previewFile,
    resetPreview,
    isFetching,
    isError,
  };
};

type TDocumentPreviewContext = ReturnType<typeof useDocumentPreview>;

export const DocumentPreviewContext = createContext<TDocumentPreviewContext>(null);

export function useDocumentPreviewContext() {
  const context = useContext(DocumentPreviewContext);
  if (!context) {
    throw new Error('useDocumentPreviewContext must be used within a DocumentPreviewContext');
  }
  return context;
}

interface IProps {
  children: React.ReactNode,
}

function DocumentPreviewContextProvider({ children } : IProps) {
  return (
    <DocumentPreviewContext.Provider value={ useDocumentPreview() }>
      { children }
    </DocumentPreviewContext.Provider>
  );
}

export default DocumentPreviewContextProvider;
