import React, { useState } from 'react';
import cn from 'classnames';
import ActionButton from '@/ui/shared/components/Button/ActionButton';
import { variantTypes } from '@/ui/shared/components/Button/ActionButton/constants';
import { Tooltip, IconType } from '@dealroadshow/uikit';
import { useDownloadContext } from '@/dataroom/application/DownloadContext';
import { isDownloadButtonDisabled } from '@/dataroom/domain/filesystemPermissions';
import { isFilesystemPreparingForArchive, isFolder } from '@/dataroom/domain/filesystem';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import * as managePermissions from '@/dataroom/domain/managePermissions';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import { Area } from '@/dataroom/domain/vo/Area';
import Dropdown from './Dropdown';
import hoverActionsStyles from '@/ui/shared/components/Tables/HoverActions/hoverActions.scss';
import styles from './downloadButton.scss';

interface IProps {
  className?: string,
  totalCount?: number,
  selected: IFilesystemListItem[],
  areas: Area[],
  isDisabled?: boolean,
  variant?: string,
  quickFilterName?: string,
  filters?: {},
  onFinish?: (e?) => void,
  onDownload?: () => void,
  dataTest: string,
  collectionLength?: number,
  isTooltipEnabled?: boolean,
  tooltipContainerClassName?: string,
  isDropdown?: boolean,
}

const DownloadButton = ({
  className,
  totalCount,
  selected,
  areas,
  isDisabled,
  variant = variantTypes.countable,
  quickFilterName,
  filters = {},
  onFinish,
  onDownload,
  dataTest,
  collectionLength = 1,
  isTooltipEnabled = false,
  tooltipContainerClassName,
  isDropdown,
}: IProps) => {
  const [isDownloadCreating, setIsDownloadCreating] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { requestDownload, download } = useDownloadContext();
  const { currentUser } = useCurrentUserContext();
  const isPreparingForArchive = isFilesystemPreparingForArchive();
  const userCanBulkDownload = managePermissions.canUserBulkDownload(currentUser);

  const isBulkDownload = !selected.length || selected.length > 1 || isFolder(selected[0]);

  const tooltipContent = (isBulkDownload && !userCanBulkDownload)
    ? 'Bulk Download Disabled'
    : `Download ${ !selected.length ? 'All' : 'Selected' }`;

  const isButtonDisabled = isDisabled ||
    isDownloadButtonDisabled(selected, userCanBulkDownload, isPreparingForArchive, collectionLength);

  const countableButtonType = isButtonDisabled && selected.length > 0 ? variantTypes.action : variantTypes.countable;

  const handleFinish = () => {
    setIsDownloadCreating(false);
    setIsDropdownOpen(false);
    onFinish && onFinish();
  };

  const handleDownload = (truncationMode? : string) => {
    setIsDownloadCreating(true);
    requestDownload({
      items: selected,
      areas,
      quickFilterName,
      filters,
      onFinish: handleFinish,
      onDownload,
      ...(truncationMode ? { truncationMode } : null),
      ...(truncationMode ? { stagingTruncationMode: truncationMode } : null),
    }).then(download);
  };

  return (
    <div className={ cn({ [styles.dropdownWrapper]: isDropdown }) }>
      <Tooltip
        disabled={ !isTooltipEnabled }
        content={ tooltipContent }
        containerClassName={ tooltipContainerClassName }
      >
        <ActionButton
          className={ cn(className, {
            [styles.iconButtonWrp]: variant === variantTypes.icon,
          }) }
          buttonClassName={ cn({
            [hoverActionsStyles.textActionItem]: variant === variantTypes.text,
            [styles.textWithIcon]: variant === variantTypes.textWithIcon,
            [styles.iconButton]: variant === variantTypes.icon,
            [styles.isFetching]: isDownloadCreating,
            [styles.isDisabled]: isDisabled,
          }) }
          title="Download"
          iconType={ IconType.download }
          isFetching={ isDownloadCreating }
          isDisabled={ isButtonDisabled || isDownloadCreating }
          variant={ variant === variantTypes.countable ? countableButtonType : variant }
          totalCount={ totalCount }
          selectedCount={ selected.length }
          onClick={ () => handleDownload() }
          dataTest={ dataTest }
        />
      </Tooltip>
      { isDropdown ? (
        <Dropdown
          handleDownload={ handleDownload }
          isOneFileSelected={ selected.length === 1 && !isFolder(selected[0]) }
          isDisabled={ isButtonDisabled }
          isDropdownOpen={ isDropdownOpen }
          handleHide={ () => setIsDropdownOpen(false) }
          handleShow={ () => setIsDropdownOpen(true) }
        />
      ) : null }
    </div>
  );
};

export default DownloadButton;
