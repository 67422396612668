import React, { createContext, useContext, useState } from 'react';
import ErrorCodeHelper from '@finsight/error-codes';
import PhysicalDeliveryRepository from '@/dataroom/infrastructure/repository/PhysicalDeliveryRepository';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { NotificationManager } from '@/Framework/Notification';
import getMessage from '@/Framework/Message/getMessage';
import { messageCodes } from '@/Framework/Message/messages';
import CountryRepository from '@/dealroadshow/infrastructure/repository/country/CountryRepository';
import CountryRepositoryI from '@/dealroadshow/infrastructure/repository/country/CountryRepositoryI';
import { useDIContext } from '@/Framework/DI/DIContext';

const usePhysicalDelivery = () => {
  const { container } = useDIContext();
  const [isRequestCopyFetching, setIsRequestCopyFetching] = useState(false);
  const [isCountriesFetching, setIsCountriesFetching] = useState(false);
  const [isDataroomDisplayNameFetching, setIsDataroomDisplayNameFetching] = useState(false);
  const [isTokenValidationFetching, setIsTokenValidationFetching] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);
  const [countriesCollection, setCountriesCollection] = useState([]);
  const [dataroomDisplayName, setDataroomDisplayName] = useState('');
  const [docState, setDocState] = useState('');
  const [canRequestPhysicalCopy, setCanRequestPhysicalCopy] = useState(false);
  const [isTokenNotValid, setIsNotTokenValid] = useState(false);

  const sendRequestCopy = async (formData, name, token, isShowSuccessMessage) => {
    setIsRequestCopyFetching(true);

    const payload = {
      ...formData,
      dataroomName: name,
      phoneNumber: formData.phoneNumber.value,
      token,
    };

    try {
      const physicalDeliveryRepository = container.get<PhysicalDeliveryRepository>(PhysicalDeliveryRepository);
      await physicalDeliveryRepository.requestCopy(payload);
      if (isShowSuccessMessage) {
        NotificationManager.success(getMessage(messageCodes.DATAROOM_REQUEST_PHYSICAL_COPY_SUCCESS));
      }
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsRequestCopyFetching(false);
    }
  };

  const checkHasAccess = async (dataroomName, token) => {
    setIsRequestCopyFetching(true);

    try {
      const physicalDeliveryRepository = container.get<PhysicalDeliveryRepository>(PhysicalDeliveryRepository);
      const response = await physicalDeliveryRepository.hasAccess({ dataroomName, token });
      setHasAccess(response.hasAccess);
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsRequestCopyFetching(false);
    }
  };

  const getCountriesList = async (token) => {
    setIsCountriesFetching(true);

    const payload = { token };

    try {
      const repository = token ? PhysicalDeliveryRepository : CountryRepository;
      const countryRepository: CountryRepositoryI = container.get(repository);
      const response = await countryRepository.getCountries(payload);
      setCountriesCollection(response);
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsCountriesFetching(false);
    }
  };

  const getDataroomDisplayName = async (token) => {
    setIsDataroomDisplayNameFetching(true);

    try {
      const physicalDeliveryRepository = container.get<PhysicalDeliveryRepository>(PhysicalDeliveryRepository);
      const response = await physicalDeliveryRepository.getDataroomDisplayName({ token });
      setDataroomDisplayName(response.displayName);
    } catch (error) {
      container.get(DataroomErrorHandler).handleError(error);
    } finally {
      setIsDataroomDisplayNameFetching(false);
    }
  };

  const checkValidTokenAndDataroomSettings = async (token) => {
    setIsTokenValidationFetching(true);

    try {
      const physicalDeliveryRepository = container.get<PhysicalDeliveryRepository>(PhysicalDeliveryRepository);
      const response = await physicalDeliveryRepository.getDataroomSettings({ token });
      setDocState(response.docState);
      setCanRequestPhysicalCopy(response.requestPhysicalCopy);
    } catch (error) {
      if (error?.getCode() === ErrorCodeHelper.getCodeByName('USER_AUTH_ERROR')) {
        setIsNotTokenValid(true);
      } else {
        container.get(DataroomErrorHandler).handleError(error);
      }
    } finally {
      setIsTokenValidationFetching(false);
    }
  };

  return {
    isRequestCopyFetching,
    isCountriesFetching,
    isDataroomDisplayNameFetching,
    isTokenValidationFetching,
    hasAccess,
    isTokenNotValid,
    countriesCollection,
    dataroomDisplayName,
    docState,
    canRequestPhysicalCopy,
    sendRequestCopy,
    checkHasAccess,
    getCountriesList,
    getDataroomDisplayName,
    checkValidTokenAndDataroomSettings,
  };
};

type PhysicalDeliveryType = ReturnType<typeof usePhysicalDelivery>;

export const PhysicalDeliveryContext = createContext<PhysicalDeliveryType>(null);

export function usePhysicalDeliveryContext() {
  const context = useContext(PhysicalDeliveryContext);
  if (!context) {
    throw new Error('usePhysicalDeliveryContext must be used within a PhysicalDeliveryContext');
  }
  return context;
}

function PhysicalDeliveryProvider({ children }) {
  return (
    <PhysicalDeliveryContext.Provider value={ usePhysicalDelivery() }>
      { children }
    </PhysicalDeliveryContext.Provider>
  );
}
export default PhysicalDeliveryProvider;
